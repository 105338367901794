<template>
  <div>
    <slot name="before-input"></slot>
    <div
        class="d-flex w-100"
        :class="
                ({
                    rounded: boxStyle === 'normal',
                    'br-50': boxStyle === 'pill',
                    'no-border-radius': boxStyle === 'rectangle',
                    'vuetiful-input-focus': isFocused && !hasError && !dontFocus && !aiCustom,
                    'text-muted': disabled,
                    'vuetiful-input-error': hasError,
                },
                borderClass)
            "
    >
      <div v-if="icon" class="py-2 d-flex align-items-center vuetiful-icon-wrapper bg-white">
        <i class="font-size-125 mt-1" :class="icon"></i>
      </div>

      <div class="position-relative d-flex align-items-center w-100">
        <label
            v-if="label"
            :for="fieldId"
            class="position-absolute top-0 mt-0 mb-0 left-0 font-weight-normal vuetiful-label d-flex align-items-center"
            :class="{
                        'vuetiful-label-small': !showPlaceholderLabel,
                        'text-muted': !aiCustom,
                        'font-size-125': aiCustom,
                    }"
        >{{ label }}</label
        >
        <input
            :id="fieldId"
            :type="fieldType"
            :name="name"
            class="no-border w-100"
            :class="{
                        rounded: boxStyle === 'normal',
                        'br-50': boxStyle === 'pill',
                        'no-border-radius': boxStyle === 'rectangle',
                        'vuetiful-input': !aiCustom,
                        'vuetiful-ai-input': aiCustom,
                        'no-bg': aiCustom
                    }"
            v-model="fieldValue"
            :disabled="disabled"
            ref="field"
            @focus="onFocus"
            @blur="onBlur"
            :autocomplete="autocomplete"
            :placeholder="placeholder"
        />
      </div>

      <div v-if="type === 'password'" class="py-2 d-flex align-items-center vuetiful-icon-wrapper">
        <i
            class="font-size-125 mt-1 cursor-pointer"
            :class="{ 'fa fa-eye-slash text-muted': isShowingPassword, 'far fa-eye': !isShowingPassword }"
            @click.stop.prevent="togglePassword"
        ></i>
      </div>
    </div>

    <slot name="after-input"></slot>

    <Transition name="fade-grow">
      <div
          v-if="hasError && currentError"
          class="mt-2 vuetiful-input-error-text d-flex align-items-center"
          :class="{ 'text-center': boxStyle === 'pill' }"
      >
        <i class="fa fa-exclamation-circle m-r-5"></i> {{ currentError }}
      </div>
    </Transition>

    <slot name="after-error"></slot>
  </div>
</template>

<script>
import {testIsNonEmptyString} from '../../../../../lib/test-and-assert/test-base';
import GenerateId from '../mixins/GenerateId';

const SUPPORTED_TYPES = ['email', 'password', 'text'];
const SUPPORTED_BOX_STYLES = ['normal', 'pill', 'rectangle'];

export default {
  name: 'TextInput',
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: function (val) {
        return SUPPORTED_TYPES.includes(val);
      },
    },
    icon: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    currentError: {
      type: String,
      default: '',
    },
    trimInput: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    boxStyle: {
      type: String,
      default: 'normal',
      validator: function (val) {
        return SUPPORTED_BOX_STYLES.includes(val);
      },
    },
    dontFocus: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    borderClass: {
      type: String,
      default: 'border',
    },
    aiCustom: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  mixins: [GenerateId],
  mounted() {
    this.fieldType = this.type;
    this.fieldId = this.generateId();

    if (testIsNonEmptyString(this.id)) {
      this.fieldId = this.id;
    }

    if (this.autoFocus) {
      this.$nextTick(() => {
        this.$refs['field'].focus();
      });
    }

    if (this.value) {
      this.fieldValue = this.value;

      return;
    }

    let autofilled = this.$refs['field'].value;

    if (testIsNonEmptyString(autofilled) && testIsNonEmptyString(autofilled.trim())) {
      this.fieldValue = autofilled.trim();
    }
  },
  watch: {
    value: function (val) {
      this.fieldValue = val;
    },
    fieldValue: function (val) {
      if (this.trimInput) {
        val = val.trim();
      }

      this.$emit('input', val);
    },
  },
  computed: {
    hasFieldValue() {
      return testIsNonEmptyString(this.fieldValue) && testIsNonEmptyString(this.fieldValue.trim());
    },
    showPlaceholderLabel() {
      return !this.isFocused && !this.hasFieldValue;
    },
    isShowingPassword() {
      return this.fieldType !== 'password';
    },
  },
  methods: {
    togglePassword() {
      if (this.disabled) {
        return;
      }

      if (this.type !== 'password') {
        return;
      }

      // browsers like to hide text inside input fields of type 'password',
      // so to show the content of the input field, we need to switch the type between 'password' and 'text'
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password';
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
  },
  data() {
    return {
      fieldType: '',
      fieldValue: '',
      fieldId: null,
      isFocused: false,
    };
  },
};
</script>
