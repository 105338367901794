var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("before-input"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex w-100",
          class:
            ({
              rounded: _vm.boxStyle === "normal",
              "br-50": _vm.boxStyle === "pill",
              "no-border-radius": _vm.boxStyle === "rectangle",
              "vuetiful-input-focus":
                _vm.isFocused &&
                !_vm.hasError &&
                !_vm.dontFocus &&
                !_vm.aiCustom,
              "text-muted": _vm.disabled,
              "vuetiful-input-error": _vm.hasError,
            },
            _vm.borderClass),
        },
        [
          _vm.icon
            ? _c(
                "div",
                {
                  staticClass:
                    "py-2 d-flex align-items-center vuetiful-icon-wrapper bg-white",
                },
                [
                  _c("i", {
                    staticClass: "font-size-125 mt-1",
                    class: _vm.icon,
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "position-relative d-flex align-items-center w-100",
            },
            [
              _vm.label
                ? _c(
                    "label",
                    {
                      staticClass:
                        "position-absolute top-0 mt-0 mb-0 left-0 font-weight-normal vuetiful-label d-flex align-items-center",
                      class: {
                        "vuetiful-label-small": !_vm.showPlaceholderLabel,
                        "text-muted": !_vm.aiCustom,
                        "font-size-125": _vm.aiCustom,
                      },
                      attrs: { for: _vm.fieldId },
                    },
                    [_vm._v(_vm._s(_vm.label))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.fieldType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fieldValue,
                        expression: "fieldValue",
                      },
                    ],
                    ref: "field",
                    staticClass: "no-border w-100",
                    class: {
                      rounded: _vm.boxStyle === "normal",
                      "br-50": _vm.boxStyle === "pill",
                      "no-border-radius": _vm.boxStyle === "rectangle",
                      "vuetiful-input": !_vm.aiCustom,
                      "vuetiful-ai-input": _vm.aiCustom,
                      "no-bg": _vm.aiCustom,
                    },
                    attrs: {
                      id: _vm.fieldId,
                      name: _vm.name,
                      disabled: _vm.disabled,
                      autocomplete: _vm.autocomplete,
                      placeholder: _vm.placeholder,
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.fieldValue)
                        ? _vm._i(_vm.fieldValue, null) > -1
                        : _vm.fieldValue,
                    },
                    on: {
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      change: function ($event) {
                        var $$a = _vm.fieldValue,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.fieldValue = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.fieldValue = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.fieldValue = $$c
                        }
                      },
                    },
                  })
                : _vm.fieldType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fieldValue,
                        expression: "fieldValue",
                      },
                    ],
                    ref: "field",
                    staticClass: "no-border w-100",
                    class: {
                      rounded: _vm.boxStyle === "normal",
                      "br-50": _vm.boxStyle === "pill",
                      "no-border-radius": _vm.boxStyle === "rectangle",
                      "vuetiful-input": !_vm.aiCustom,
                      "vuetiful-ai-input": _vm.aiCustom,
                      "no-bg": _vm.aiCustom,
                    },
                    attrs: {
                      id: _vm.fieldId,
                      name: _vm.name,
                      disabled: _vm.disabled,
                      autocomplete: _vm.autocomplete,
                      placeholder: _vm.placeholder,
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.fieldValue, null) },
                    on: {
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      change: function ($event) {
                        _vm.fieldValue = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fieldValue,
                        expression: "fieldValue",
                      },
                    ],
                    ref: "field",
                    staticClass: "no-border w-100",
                    class: {
                      rounded: _vm.boxStyle === "normal",
                      "br-50": _vm.boxStyle === "pill",
                      "no-border-radius": _vm.boxStyle === "rectangle",
                      "vuetiful-input": !_vm.aiCustom,
                      "vuetiful-ai-input": _vm.aiCustom,
                      "no-bg": _vm.aiCustom,
                    },
                    attrs: {
                      id: _vm.fieldId,
                      name: _vm.name,
                      disabled: _vm.disabled,
                      autocomplete: _vm.autocomplete,
                      placeholder: _vm.placeholder,
                      type: _vm.fieldType,
                    },
                    domProps: { value: _vm.fieldValue },
                    on: {
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.fieldValue = $event.target.value
                      },
                    },
                  }),
            ]
          ),
          _vm._v(" "),
          _vm.type === "password"
            ? _c(
                "div",
                {
                  staticClass:
                    "py-2 d-flex align-items-center vuetiful-icon-wrapper",
                },
                [
                  _c("i", {
                    staticClass: "font-size-125 mt-1 cursor-pointer",
                    class: {
                      "fa fa-eye-slash text-muted": _vm.isShowingPassword,
                      "far fa-eye": !_vm.isShowingPassword,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.togglePassword.apply(null, arguments)
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm._t("after-input"),
      _vm._v(" "),
      _c("Transition", { attrs: { name: "fade-grow" } }, [
        _vm.hasError && _vm.currentError
          ? _c(
              "div",
              {
                staticClass:
                  "mt-2 vuetiful-input-error-text d-flex align-items-center",
                class: { "text-center": _vm.boxStyle === "pill" },
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle m-r-5" }),
                _vm._v(" " + _vm._s(_vm.currentError) + "\n    "),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._t("after-error"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }