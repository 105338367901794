import '../../../scss/critical.scss';
import '../../../scss/index.scss';
import '../../../scss/components/cms/_cms-index-page.scss';
import '../../../scss/components/_hero.scss';
import Vue from 'vue';
import ConversionPairSelector from '../../qaamgo/modules/sat/components/conversionpair/ConversionPairSelector.vue';

const $ = require('jquery');
global.$ = global.jQuery = $;

import(
    /* webpackMode: "eager" */
    '../qg-main'
).then(function () {
    $(function () {
        $(document).on('click', '.tile-sublinks select, .tile-sublinks button', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        $(document).on('click', '.tile-sublinks button', function (e) {
            e.preventDefault();
            e.stopPropagation();
            window.location = $(this).closest('form').find('select').val();
        });
        $(document).on('change', '.tile-sublinks select', function (e) {
            e.preventDefault();
            e.stopPropagation();
            window.location = $(this).val();
        });

        const conversionPairSelector = document.getElementById('conversionPairSelector');

        if (conversionPairSelector) {
            new Vue({
                el: '#conversionPairSelector',
                name: 'ConversionPairSelectorRoot',
                components: { ConversionPairSelector },
                render(h) {
                    return h(ConversionPairSelector);
                },
            });
        }
    });
});
